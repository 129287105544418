import CustomAxios from "../utility/customAxios";

type EditorMetaInfoType = {
  content_cn: string;
  content_en: string;
};
export type BaseMetaInfoType = EditorMetaInfoType & {
  _id: string;
  cover: string;
  title_cn: string;
  title_en: string;
  desc_cn: string;
  desc_en: string;
};

export type UpdateNewInfoType = Partial<BaseMetaInfoType> & {
  id: string;
};

export type ArticalMetaType = BaseMetaInfoType;
// type WorkMetaType = BaseMetaInfoType;
// type AbountMetaType = EditorMetaInfoType;
// type ContactMetaType = EditorMetaInfoType;
// type PublicationMetaType = BaseMetaInfoType;
// type ExhibitionMetaType = BaseMetaInfoType;

export const login = (data: { name: string; password: string }) => {
  return CustomAxios.post<
    any,
    {
      status: number;
      data: string;
      token?: string;
    }
  >("/api/login", data);
};
export const register = (data: { name: string; password: string }) => {
  return CustomAxios.post<
    any,
    {
      status: number;
      data: string;
      token?: string;
    }
  >("/api/register", data);
};
export const getMenu = () => CustomAxios.get<any, any>("/api/menu");
export const getVideo = () => CustomAxios.get<any, any>("/api/video");
export const updateMenu = (data: {
  key: string;
  label_cn: string;
  label_en: string;
}) =>
  CustomAxios.put<any, any>("/api/menu", data, {
    headers: { authorization: `jwt ${window.localStorage.getItem("jwt")}` },
  });
export const updateVideo = (data: {
  picVideoUrl?: string;
  mobileVideoUrl?: string;
}) =>
  CustomAxios.put<any, any>("/api/video", data, {
    headers: { authorization: `jwt ${window.localStorage.getItem("jwt")}` },
  });
export const getDownloadUrl = (key: string) =>
  CustomAxios.get<any, any>(`/api/downloadUrl?key=${key}`);
