export enum router {
  work = "work",
  biography = "biography",
  contact = "contact",
}

export const TitleMap: { [key: string]: string } = {
  work: "作品列表",
  biography: "编辑简历",
  contact: "编辑联系方式",
  publication: "出版物列表",
  exhibition: "展览列表",
  new: "新闻列表",
};
