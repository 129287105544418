import stringifyq from "query-string";
import { fetchUtils } from "react-admin";
import { router } from "./const";
import { baseURL } from "./customAxios";
const stringify = stringifyq.stringify;
// local test API origin
export const apiUrl = `${baseURL}/api`;
export const httpClient = fetchUtils.fetchJson;
export const headers = new Headers({
  authorization: `jwt ${window.localStorage.getItem("jwt")}`,
});
const isSpecialOne = (resource: string) => {
  return resource === router.biography || resource === router.contact;
};
export const getToken = async () => {
  try {
    const res = await httpClient(`${apiUrl}/token`, {
      headers,
    });
    return res.json.data;
  } catch (error) {
    console.log("upload image error:", error);
  }
};

export type GetListParamsType = {
  pageNum?: number;
  lastPageId?: string;
  filter?: string[];
};
const dataProvider = {
  getList: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const meta = params.meta;
    const query = {
      meta: JSON.stringify(meta),
      pageNum: page,
      pageSize: perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data.map((item: any) => ({ ...item, id: item._id })),
        total: json.total,
      };
    });
  },

  getOne: (resource: string, params: any) => {
    const baseUrl = `${apiUrl}/${resource}`;
    const query = isSpecialOne(resource) ? baseUrl : `${baseUrl}/${params.id}`;
    return httpClient(query).then(({ json }) => {
      return {
        data: {
          ...json.data,
          id: json.data?._id,
        },
      };
    });
  },
  getMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  // 暂时没用
  getManyReference: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range")?.split("/").pop() ?? "", 10),
    }));
  },

  create: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      headers,
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
      .catch((err) => {
        console.error("request error:", err);
        return err;
      }),

  update: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      headers,
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json.data })),

  // 暂时没用
  updateMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      headers,
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
      headers,
    }).then(({ json }) => {
      return { data: json };
    }),

  // 暂时没用
  deleteMany: (resource: string, params: any) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: "DELETE",
      headers,
      body: JSON.stringify(params.ids),
    }).then(({ json }) => ({ data: json.data }));
  },
  reorder(
    resource: string,
    params: {
      data: { preId?: string; nextId?: string; curId: string };
    }
  ) {
    return httpClient(`${apiUrl}/sort${resource}`, {
      method: "PUT",
      headers,
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      return { data: json };
    });
  },
};

export default dataProvider;
