import { Edit, useRecordContext, useResourceDefinition } from "react-admin";
import { WorkTemplate } from "../../template/work";

const PostTitle = () => {
  const record = useRecordContext();
  const {
    options: { label },
  } = useResourceDefinition();
  return <span>正在编辑{record ? `${label}: ${record.title_cn}` : ""}</span>;
};
export const WorkEdit = () => {
  return (
    <Edit sx={{ width: "1210px" }} title={<PostTitle />}>
      <WorkTemplate />
    </Edit>
  );
};
