import axios from "axios";
// prod
export const baseURL = "http://api.caoyu.art";

// dev CY
// export const baseURL = "http://localhost:8081";
// dev HQY
// export const baseURL = "http://api.huqingyan.com:8082";

const CustomAxios = axios.create({
  baseURL,
});
export const toSnackCase: any = (object: any) => {
  let transformedObject = object;
  if (typeof object === "object" && object !== null) {
    if (object instanceof Array) {
      transformedObject = object.map(toSnackCase);
    } else {
      transformedObject = {};
      for (const key in object) {
        if (object[key] !== undefined) {
          const newKey = key
            .replace(/\.?([A-Z]+)/g, function (_, y) {
              return "_" + y.toLowerCase();
            })
            .replace(/^_/, "");
          transformedObject[newKey] = toSnackCase(object[key]);
        }
      }
    }
  }
  return transformedObject;
};

CustomAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

CustomAxios.interceptors.request.use(
  (config) => {
    config.data = toSnackCase(config.data);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default CustomAxios;
