import { login } from "../api";
import { checkLoginStatus } from "./checkLogin";
export const authProvider = {
  // called when the user attempts to log in
  login: async (params: { username: string; password: string }) => {
    try {
      const { username: name, password } = params;

      const res = await login({ name, password });
      if (res?.token) {
        localStorage.setItem("jwt", res.token);
      } else {
        throw new Error("login error");
      }
    } catch (error) {
      console.log("error:", error);
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("jwt");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }: { status: number }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("jwt");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    console.log("checkauth");
    return checkLoginStatus() ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    console.log("getPermissions", checkLoginStatus());
    return checkLoginStatus() ? Promise.resolve() : Promise.reject();
  },
};
