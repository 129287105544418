import Box from "@mui/material/Box/Box";
import { useQuery } from "@tanstack/react-query";
import { useAuthenticated } from "react-admin";
import { getVideo } from "../../api";
import WizardForm from "../../components/wizardForm";
import WizardFormStep from "../../components/wizardFormStep";
import UploadVideo, { VideoType } from "./uploadVideo";
export default function Home() {
  // const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ["video"],
    queryFn: getVideo,
    refetchOnWindowFocus: false,
  });

  useAuthenticated();
  if (!data) {
    return null;
  }

  const { pcurl, murl } = data.data;
  return (
    <WizardForm autoSubmit>
      <WizardFormStep label="编辑横版视频(PC端使用)">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 5,
          }}
        >
          <UploadVideo url={pcurl} />
        </Box>
      </WizardFormStep>
      <WizardFormStep label="编辑竖版视频(手机端使用)">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 5,
          }}
        >
          <UploadVideo type={VideoType.vertical} url={murl} />
        </Box>
      </WizardFormStep>
    </WizardForm>
  );
}
