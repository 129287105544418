import { useAuthenticated } from "react-admin";
import List from "../../template/list";
const colums = [
  {
    accessorKey: "title_cn",
    header: "标题",
  },
  {
    accessorKey: "coverUrl",
    header: "封面",
    Cell(props: any) {
      const { renderedCellValue } = props;
      return (
        <img
          style={{ maxHeight: "100px", width: "auto" }}
          src={`${renderedCellValue}?imageMogr2/auto-orient/thumbnail/!160x100r/format/webp/interlace/1/blur/1x0/quality/70|imageslim`}
        />
      );
    },
  },
];

const meta = ["order", "title_cn", "desc_cn", "coverUrl"];
export default function ExhibitionList() {
  useAuthenticated();
  return <List columns={colums} meta={meta} />;
}
