import BackupIcon from "@mui/icons-material/Backup";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import { Upload } from "antd";
import { nanoid } from "nanoid";
import * as qiniu from "qiniu-js";
import { useNotify } from "react-admin";

import type { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { useState } from "react";
import { updateVideo } from "../../api";
import { baseLinkUrl } from "../../components/uploadImage";
import { getToken } from "../../utility/dataProvider";

export enum VideoType {
  horizontal = "pcurl",
  vertical = "murl",
}
export type UploadVideoPropsType = {
  url: string;
  type?: VideoType;
};

const videoize = [480, 270];
export default function UploadVideo(props: UploadVideoPropsType) {
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const { type = VideoType.horizontal, url } = props;
  const [videoUrl, setVideoUrl] = useState(url);
  const customRequest = async (req: RcCustomRequestOptions) => {
    const { file } = req;
    const config = {
      useCdnDomain: true,
      chunkSize: 2,
      concurrentRequestLimit: 5,
    };
    setLoading(true);
    try {
      const token = await getToken();
      const observable = qiniu.upload(
        file as File,
        nanoid(),
        token,
        undefined,
        config
      );
      observable.subscribe(
        null,
        (error) => {
          console.error("upload image failed:", error);
          setLoading(false);
        },
        async (res: any) => {
          const key = res?.key;
          const url = `${baseLinkUrl}${key}`;
          setLoading(false);
          setVideoUrl(url);
          try {
            await updateVideo({ [type]: url });
            notify("保存成功", { type: "success" });
          } catch (error) {
            notify("保存失败，请稍后重试", { type: "error" });
          }

          // TODO： save the url
        }
      );
    } catch (error) {
      // setLoading(false);
      console.error("upload image failed:", error);
    }
  };

  const width = type === VideoType.vertical ? videoize[1] : videoize[0];
  const height = type === VideoType.vertical ? videoize[0] : videoize[1];
  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        border: "1px dotted gray",
        py: 5,
        mx: 25,
      }}
    >
      <Upload showUploadList={false} customRequest={customRequest}>
        <Button>
          <BackupIcon />
          <span>{type === VideoType.vertical ? "竖版" : "横版"}视频</span>
        </Button>
      </Upload>
      <Box>
        <Box
          sx={{
            textAlign: "center",
            mb: 2,
          }}
        >
          {type === VideoType.vertical
            ? "手机端推荐(9:16)"
            : "电脑端推荐(16:9)"}
          视频
        </Box>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width,
              height,
              bgcolor: "rgba(0,0,0,0.1)",
            }}
          >
            上传视频中...
          </Box>
        ) : (
          <Paper
            sx={{
              width,
              height,
              bgcolor: "rgba(0,0,0,0.5)",
            }}
          >
            <video
              src={videoUrl}
              width={width}
              height={height}
              // autoPlay={!!videoUrl}
              // poster="/your-path/poster.jpg"
              controls
              // more events...
            />
          </Paper>
        )}
      </Box>
    </Box>
  );
}
