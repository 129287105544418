import { ActionIcon, Tooltip } from "@mantine/core";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Popover from "@mui/material/Popover/Popover";
import { IconTrash } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useDelete, useResourceContext } from "react-admin";
export default function DeleteActionButton(props: any) {
  const { row } = props;
  const [deleteOne] = useDelete();
  const resource = useResourceContext();
  const buttonRef = useRef<any | null>(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    deleteOne(resource, { id: row.id });
  };
  return (
    <>
      <Tooltip withArrow position="bottom" label="Delete">
        <ActionIcon color="red">
          <IconTrash
            onClick={() => {
              setOpen(true);
            }}
            ref={buttonRef}
          />
        </ActionIcon>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="warning" sx={{ fontSize: 14, p: 2, pb: 1 }}>
          <AlertTitle sx={{ fontSize: 16 }}>确认要删除吗？</AlertTitle>

          <Box sx={{ width: "100%", mt: 2 }}>
            <Button
              variant="contained"
              size="small"
              sx={{ fontSize: 14, mr: 1 }}
              onClick={() => {
                setOpen(false);
              }}
            >
              取消
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ fontSize: 14 }}
              onClick={handleClick}
            >
              确认
            </Button>
          </Box>
        </Alert>
      </Popover>
    </>
  );
}
