import CollectionsIcon from "@mui/icons-material/Collections";
import ContactsIcon from "@mui/icons-material/Contacts";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MuseumIcon from "@mui/icons-material/Museum";
import PersonIcon from "@mui/icons-material/Person";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Biograhpy from "../pages/biography";
import { ExhibitionCreate } from "../pages/exhibition/create";
import { ExhibitionEdit } from "../pages/exhibition/edit";
import ExhibitionList from "../pages/exhibition/list";
import Home from "../pages/home";
import { WorkCreate } from "../pages/works/create";
import { WorkEdit } from "../pages/works/edit";
import WokrsList from "../pages/works/list";
import { router } from "../utility/const";
export const Config: {
  [key: string]: {
    icon: OverridableComponent<SvgIconTypeMap<Record<string, never>, "svg">> & {
      muiName: string;
    };
    label: string;
    list: () => JSX.Element | null;
    create?: () => JSX.Element;
    edit?: () => JSX.Element;
  };
} = {
  home: {
    icon: HomeIcon,
    label: "首页",
    list: Home,
  },
  work: {
    icon: CollectionsIcon,
    label: "作品",
    list: WokrsList,
    create: WorkCreate,
    edit: WorkEdit,
  },
  exhibition: {
    icon: MuseumIcon,
    label: "展览",
    list: ExhibitionList,
    create: ExhibitionCreate,
    edit: ExhibitionEdit,
  },
  publication: {
    icon: LibraryBooksIcon,
    label: "出版物",
    list: ExhibitionList,
    create: ExhibitionCreate,
    edit: ExhibitionEdit,
  },
  new: {
    icon: FiberNewIcon,
    label: "新闻",
    list: ExhibitionList,
    create: ExhibitionCreate,
    edit: ExhibitionEdit,
  },
  [router.biography]: {
    icon: PersonIcon,
    list: Biograhpy,
    label: "简历",
  },
  [router.contact]: {
    icon: ContactsIcon,
    list: Biograhpy,
    label: "联系",
  },
};
